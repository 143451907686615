import { Navigate, useLocation } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { BackgroundLocationState } from '../BackgroundLocationState';
import { NoPlannerPage, OnboardingScreen, WelcomePage } from '../components';
import { InitialStateLayout } from './InitialStateLayout';
import { OnboardedRoute } from './OnboardedRoute';
import { OnboardedRoutes } from './OnboardedRoutes';

export function MainRoutes() {
  const location = useLocation();
  const state = (location.state ?? {}) as BackgroundLocationState;

  return useRoutes(
    [
      {
        path: '',
        element: <InitialStateLayout />,
        children: [
          { path: '/welcome', element: <WelcomePage /> },
          { path: '/onboarding', element: <OnboardingScreen /> },
          { path: '/noPlanner', element: <NoPlannerPage /> },
          { path: '/', element: <OnboardedRoute />, children: OnboardedRoutes }
        ]
      },
      { path: '*', element: <Navigate to="/" replace /> }
    ],
    state.backgroundLocation ?? location
  );
}
