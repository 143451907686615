import { CalendarDayOptions, CalendarFilters, CalendarWeekDensity, Picture } from '@/models';
import { Preferences } from '@capacitor/preferences';
import { captureException } from '@sentry/react';
import { formatISO, parseISO, set } from 'date-fns';
import { ObservableMap, action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import {
  ApplicationSettingsStorage,
  DateService,
  StoredActiveDashboard,
  SubscriptionBannerDismissState,
  UserService
} from '../contracts';

export const DefaultDemoModeMockDate = set(new Date(), { year: 2022, month: 2, date: 14, hours: 15, minutes: 0 });

export class AppApplicationSettingsStorage implements ApplicationSettingsStorage {
  private _userSettings = observable.map<string, string>();
  private _globalSettings = observable.map<string, string>();

  private static readonly UserStorageKeys = {
    ActiveDashboard: 'today_active_dashboard',
    CalendarDayOptions: 'calendar_day_options',
    CalendarMonthDisplayedCourseSections: 'calendar_month_displayed_course_sections',
    CalendarMonthFilters: 'calendar_month_filters',
    CalendarShowFreePeriods: 'calendar_show_free_periods',
    CalendarShowPeriodLabels: 'calendar_show_period_labels',
    CalendarWeekDensity: 'calendar_week_density',
    CalendarWeekDisplayedCourseSections: 'calendar_week_displayed_course_sections',
    CalendarWeekFilters: 'calendar_week_filters',
    CalendarWeekShowWeekends: 'calendar_week_show_weekends',
    IsDemoMode: 'today_demo_mode',
    PlannerItemsDetailsFilteredKinds: 'planner_items_filtered_kinds',
    PlannerItemsMaximumDate: 'planner_items_maximum_date',
    PlannerItemsMinimumDate: 'planner_items_minimum_date',
    PlannerItemsShowNoDateItems: 'planner_items_show_no_date_items',
    PlannerItemsShowPastItems: 'planner_items_show_past_items',
    SubscriptionBannerDismissState: 'subscription_banner_dismiss_state',
    TablePageSize: 'page_size',
    UserDashboardBannerIsDismissed: 'user_dashboard_banner_is_dismissed'
  };

  private static readonly GlobalStorageKeys = {
    ReduceTransparency: 'today_reduce_transparency',
    Theme: 'today_theme_variant',
    BackgroundImage: 'today_background_image',
    UseCustomPrimaryColor: 'today_use_custom_primary_color',
    CustomPrimaryColor: 'today_custom_primary_color',
    Locale: 'today_current_locale',
    GoogleClickId: 'today_gclid',
    FacebookClickId: 'today_fbclid',
    HubspotContactId: 'today_hscid',
    IsSidebarCollapsed: 'is__sidebar_collapsed'
  };

  constructor(user: UserService, dateService: DateService) {
    makeObservable(this);
    void this.setInitialGlobalSettings();

    if (this.isDemoMode) {
      dateService.mockCurrentDate(DefaultDemoModeMockDate);
    }

    // Only changes, important!
    reaction(
      () => user.currentUser != null,
      (isConnected) => {
        const updateValues = async () => {
          if (isConnected) {
            const existingValues = await this.getExistingValues(
              Object.values(AppApplicationSettingsStorage.UserStorageKeys)
            );
            runInAction(() => this._userSettings.replace(existingValues));
          } else {
            // It's important that we only observe changes to "isConnected" and not the initial value.
            // Otherwise, we'd be clearing all settings every time we start the app.
            await this.clearExistingValues(Object.values(AppApplicationSettingsStorage.UserStorageKeys));
            runInAction(() => this._userSettings.clear());
            // Keep global settings
          }
        };

        void updateValues();
      }
    );
  }

  @computed
  get locale(): string | undefined {
    return this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.Locale);
  }

  set locale(value: string | undefined) {
    void this.setOrResetValue(this._globalSettings, AppApplicationSettingsStorage.GlobalStorageKeys.Locale, value);
  }

  @computed
  get themeVariant(): string | undefined {
    return this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.Theme);
  }

  set themeVariant(value: string | undefined) {
    void this.setOrResetValue(this._globalSettings, AppApplicationSettingsStorage.GlobalStorageKeys.Theme, value);
  }

  @computed
  get activeDashboard(): StoredActiveDashboard | undefined {
    const value = this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.ActiveDashboard);
    return value == null ? value : (JSON.parse(value) as StoredActiveDashboard);
  }

  set activeDashboard(value: StoredActiveDashboard | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.ActiveDashboard,
      JSON.stringify(value)
    );
  }

  @computed
  get backgroundImage(): Picture | undefined {
    const value = this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.BackgroundImage);
    return value == null ? value : (JSON.parse(value) as Picture);
  }

  set backgroundImage(value: Picture | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.BackgroundImage,
      JSON.stringify(value)
    );
  }

  @computed
  get reduceTransparency(): boolean | undefined {
    const value = this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.ReduceTransparency);
    return value == null ? value : (JSON.parse(value) as boolean);
  }

  set reduceTransparency(value: boolean | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.ReduceTransparency,
      JSON.stringify(value)
    );
  }

  @computed
  get useCustomPrimaryColor(): boolean | undefined {
    const value = this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.UseCustomPrimaryColor);
    return value == null ? value : (JSON.parse(value) as boolean);
  }

  set useCustomPrimaryColor(value: boolean | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.UseCustomPrimaryColor,
      JSON.stringify(value)
    );
  }

  @computed
  get customPrimaryColor(): string | undefined {
    const value = this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.CustomPrimaryColor);
    return value == null ? value : (JSON.parse(value) as string);
  }

  set customPrimaryColor(value: string | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.CustomPrimaryColor,
      JSON.stringify(value)
    );
  }

  @computed
  get googleClickId(): string | undefined {
    return this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.GoogleClickId);
  }

  set googleClickId(value: string | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.GoogleClickId,
      value
    );
  }

  @computed
  get facebookClickId(): string | undefined {
    return this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.FacebookClickId);
  }

  set facebookClickId(value: string | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.FacebookClickId,
      value
    );
  }

  @computed
  get hubspotContactId(): string | undefined {
    return this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.HubspotContactId);
  }

  set hubspotContactId(value: string | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.HubspotContactId,
      value
    );
  }

  @computed
  get isDemoMode(): boolean | undefined {
    return this.stringToBoolean(this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.IsDemoMode) ?? '');
  }

  set isDemoMode(value: boolean | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.IsDemoMode,
      JSON.stringify(value)
    );
  }

  @computed
  get subscriptionBannerDismissState(): SubscriptionBannerDismissState | undefined {
    const value = this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.SubscriptionBannerDismissState);
    return value == null ? value : (JSON.parse(value) as SubscriptionBannerDismissState);
  }

  set subscriptionBannerDismissState(value: SubscriptionBannerDismissState) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.SubscriptionBannerDismissState,
      JSON.stringify(value)
    );
  }

  @computed
  get isSidebarCollapsed(): boolean | undefined {
    return this.stringToBoolean(
      this._globalSettings.get(AppApplicationSettingsStorage.GlobalStorageKeys.IsSidebarCollapsed) ?? ''
    );
  }

  set isSidebarCollapsed(value: boolean | undefined) {
    void this.setOrResetValue(
      this._globalSettings,
      AppApplicationSettingsStorage.GlobalStorageKeys.IsSidebarCollapsed,
      JSON.stringify(value)
    );
  }

  @computed
  get calendarDayOptions(): CalendarDayOptions | undefined {
    const value = this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarDayOptions);
    return value == null ? value : (JSON.parse(value) as CalendarDayOptions);
  }

  set calendarDayOptions(value: CalendarDayOptions | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarDayOptions,
      JSON.stringify(value)
    );
  }

  @computed
  get calendarWeekFilters(): CalendarFilters | undefined {
    const value = this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekFilters);
    return value == null ? value : (JSON.parse(value) as CalendarFilters);
  }

  set calendarWeekFilters(value: CalendarFilters | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekFilters,
      JSON.stringify(value)
    );
  }

  @computed
  get calendarWeekDensity(): CalendarWeekDensity | undefined {
    const value = this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekDensity);
    return value == null ? value : (value as CalendarWeekDensity);
  }

  set calendarWeekDensity(value: CalendarWeekDensity | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekDensity,
      value
    );
  }

  @computed
  get calendarWeekShowWeekends(): boolean | undefined {
    return this.stringToBoolean(
      this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekShowWeekends) ?? ''
    );
  }

  set calendarWeekShowWeekends(value: boolean | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekShowWeekends,
      JSON.stringify(value)
    );
  }

  @computed
  get calendarMonthFilters(): CalendarFilters | undefined {
    const value = this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarMonthFilters);
    return value == null ? value : (JSON.parse(value) as CalendarFilters);
  }

  set calendarMonthFilters(value: CalendarFilters | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarMonthFilters,
      JSON.stringify(value)
    );
  }

  @computed
  get calendarShowPeriodLabels(): boolean | undefined {
    return this.stringToBoolean(
      this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarShowPeriodLabels) ?? ''
    );
  }

  set calendarShowPeriodLabels(value: boolean | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarShowPeriodLabels,
      JSON.stringify(value)
    );
  }

  @computed
  get calendarShowFreePeriods(): boolean | undefined {
    return this.stringToBoolean(
      this._userSettings.get(AppApplicationSettingsStorage.UserStorageKeys.CalendarShowFreePeriods) ?? ''
    );
  }

  set calendarShowFreePeriods(value: boolean | undefined) {
    void this.setOrResetValue(
      this._userSettings,
      AppApplicationSettingsStorage.UserStorageKeys.CalendarShowFreePeriods,
      JSON.stringify(value)
    );
  }

  async tablePageSizeForKey(tableKey: string): Promise<number | undefined> {
    const key = this.getStorageKeyForValue([tableKey], AppApplicationSettingsStorage.UserStorageKeys.TablePageSize);
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value == null ? value : (JSON.parse(value) as number);
  }

  setTablePageSizeForKey(tableKey: string, pageSize: number) {
    const key = this.getStorageKeyForValue([tableKey], AppApplicationSettingsStorage.UserStorageKeys.TablePageSize);
    void this.setOrResetValue(this._userSettings, key, JSON.stringify(pageSize));
  }

  async calendarWeekDisplayedCourseSectionsForUserDashboard(userDashboardId: string): Promise<string[] | undefined> {
    const key = this.getStorageKeyForValue(
      [userDashboardId],
      AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekDisplayedCourseSections
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value == null ? value : (JSON.parse(value) as string[]);
  }

  setCalendarWeekDisplayedCourseSectionsForUserDashboard(
    courseSectionsIds: string[] | undefined,
    userDashboardId: string
  ) {
    const key = this.getStorageKeyForValue(
      [userDashboardId],
      AppApplicationSettingsStorage.UserStorageKeys.CalendarWeekDisplayedCourseSections
    );
    void this.setOrResetValue(
      this._userSettings,
      key,
      courseSectionsIds != null ? JSON.stringify(courseSectionsIds) : undefined
    );
  }

  async calendarMonthDisplayedCourseSectionsForUserDashboard(userDashboardId: string): Promise<string[] | undefined> {
    const key = this.getStorageKeyForValue(
      [userDashboardId],
      AppApplicationSettingsStorage.UserStorageKeys.CalendarMonthDisplayedCourseSections
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value == null ? value : (JSON.parse(value) as string[]);
  }

  setCalendarMonthDisplayedCourseSectionsForUserDashboard(
    courseSectionsIds: string[] | undefined,
    userDashboardId: string
  ) {
    const key = this.getStorageKeyForValue(
      [userDashboardId],
      AppApplicationSettingsStorage.UserStorageKeys.CalendarMonthDisplayedCourseSections
    );
    void this.setOrResetValue(
      this._userSettings,
      key,
      courseSectionsIds != null ? JSON.stringify(courseSectionsIds) : undefined
    );
  }

  async plannerItemsMinimumDate(plannerId: string, courseSectionId: string | undefined): Promise<Date | undefined> {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsMinimumDate
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value != null ? parseISO(value) : undefined;
  }

  setPlannerItemsMinimumDate(date: Date | undefined, plannerId: string, courseSectionId: string | undefined) {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsMinimumDate
    );
    void this.setOrResetValue(this._userSettings, key, date != null ? formatISO(date) : undefined);
  }

  async plannerItemsMaximumDate(plannerId: string, courseSectionId: string | undefined): Promise<Date | undefined> {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsMaximumDate
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value != null ? parseISO(value) : undefined;
  }

  setPlannerItemsMaximumDate(date: Date | undefined, plannerId: string, courseSectionId: string | undefined) {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsMaximumDate
    );
    void this.setOrResetValue(this._userSettings, key, date != null ? formatISO(date) : undefined);
  }

  async plannerItemsShowPastItems(
    plannerId: string,
    courseSectionId: string | undefined
  ): Promise<boolean | undefined> {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsShowPastItems
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return this.stringToBoolean(value ?? '');
  }

  setPlannerItemsShowPastItems(value: boolean | undefined, plannerId: string, courseSectionId: string | undefined) {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsShowPastItems
    );
    void this.setOrResetValue(this._userSettings, key, JSON.stringify(value));
  }

  async plannerItemsShowNoDateItems(
    plannerId: string,
    courseSectionId: string | undefined
  ): Promise<boolean | undefined> {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsShowNoDateItems
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return this.stringToBoolean(value ?? '');
  }

  setPlannerItemsShowNoDateItems(value: boolean | undefined, plannerId: string, courseSectionId: string | undefined) {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsShowNoDateItems
    );
    void this.setOrResetValue(this._userSettings, key, JSON.stringify(value));
  }

  async plannerItemsFilteredKinds(
    plannerId: string,
    courseSectionId: string | undefined
  ): Promise<string[] | undefined> {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsDetailsFilteredKinds
    );
    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value == null ? value : (JSON.parse(value) as string[]);
  }
  setPlannerItemsFilteredKinds(
    value: string[] | undefined,
    plannerId: string,
    courseSectionId: string | undefined
  ): void {
    const key = this.getStorageKeyForValue(
      [courseSectionId ?? plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.PlannerItemsDetailsFilteredKinds
    );
    void this.setOrResetValue(this._userSettings, key, JSON.stringify(value));
  }

  async userDashboardBannerIsDismissed(plannerId: string, bannerId: string): Promise<boolean | undefined> {
    const key = this.getStorageKeyForValue(
      [bannerId, plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.UserDashboardBannerIsDismissed
    );

    const value = await this.getStoredValueForKey(key, this._userSettings);
    return value == null ? value : (JSON.parse(value) as boolean);
  }

  setUserDashboardBannerIsDismissed(value: boolean, plannerId: string, bannerId: string) {
    const key = this.getStorageKeyForValue(
      [bannerId, plannerId],
      AppApplicationSettingsStorage.UserStorageKeys.UserDashboardBannerIsDismissed
    );

    void this.setOrResetValue(this._userSettings, key, JSON.stringify(value));
  }

  private getStorageKeyForValue(params: string[], storageKey: string): string {
    return `${params.join('_')}_${storageKey}`;
  }

  @action
  private async setOrResetValue<T extends string>(
    map: ObservableMap<string, string>,
    key: string,
    value: T | undefined
  ) {
    if (value == null) {
      await Preferences.remove({ key });
      runInAction(() => {
        map.delete(key);
      });
    } else {
      await Preferences.set({ key, value });
      runInAction(() => {
        map.set(key, value);
      });
    }
  }

  private async setInitialGlobalSettings() {
    const globalExistingValues = await this.getExistingValues(
      Object.values(AppApplicationSettingsStorage.GlobalStorageKeys)
    );
    this._globalSettings.replace(globalExistingValues);
  }

  private async getExistingValues(keys: string[]): Promise<Record<string, string>> {
    const fetchValues = keys.map((key) =>
      (async () => {
        const value = await Preferences.get({ key });
        return { key, value: value.value ?? undefined };
      })()
    );

    const allValues = await Promise.all(fetchValues);

    return allValues.reduce<Record<string, string>>((values, currentValue) => {
      if (currentValue.value != null) {
        values[currentValue.key] = currentValue.value;
      }

      return values;
    }, {});
  }

  private async clearExistingValues(keys: string[]) {
    await Promise.all(
      keys.map(async (key) => {
        await Preferences.remove({ key });
      })
    );
  }

  private async getStoredValueForKey(key: string, storage: ObservableMap<string, string>): Promise<string | undefined> {
    return storage.get(key) ?? (await Preferences.get({ key })).value ?? undefined;
  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue) as boolean;
    } catch (e) {
      captureException(e);
      return undefined;
    }
  }
}
