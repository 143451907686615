import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Button, Divider, Grid2, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { Dialog } from '../utils';
import { Product } from './Product';

export interface SubscriptionsDialogProps {
  isOpen: boolean;
  schoolId?: string;
}

export const SubscriptionsDialog = observer(({ isOpen, schoolId }: SubscriptionsDialogProps) => {
  const { analytics, route } = useServices();
  const strings = LocalizedStrings.subscriptions.manage;
  const theme = useTheme();
  const plannerId = useActivePlannerId();

  const navigate = useNavigate();

  function close() {
    navigate(-1);
    return Promise.resolve();
  }

  function onSubscribe(sharedSchoolIds: string[]) {
    if (sharedSchoolIds.length === 1) {
      navigate(route.resolveAdminSchoolInformationLocation(sharedSchoolIds[0]), { replace: true });
    }

    return Promise.resolve();
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createSubscriptionsViewModel(plannerId, schoolId, close, onSubscribe),
    [plannerId, schoolId]
  );

  useEffect(() => {
    analytics.logScreenView_SubscriptionPlans();
  }, []);

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.title()}
      width="md"
      renderData={() => (
        <Grid2 container spacing={3} sx={{ width: '100%' }}>
          <Grid2 size={{ xs: 12 }}>
            <Typography>{strings.message()}</Typography>
          </Grid2>

          {viewModel.products.map((product, _, ps) => (
            <Grid2
              key={`subscription-product-${product.id}`}
              size={{ xs: 12, sm: 6 }}
              offset={{ sm: ps.length === 1 ? 3 : 0 }}
            >
              <Product sx={{ height: '100%', width: '100%' }} viewModel={product} />
            </Grid2>
          ))}

          <Grid2 size={{ xs: 12, sm: 10 }} offset={{ sm: 1 }}>
            <Stack
              spacing={1}
              sx={{
                px: 2
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  textAlign: 'center',
                  color: theme.palette.text.secondary
                }}
              >
                {strings.legalText()}
              </Typography>

              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button
                  size="small"
                  onClick={() => (viewModel.currency = 'usd')}
                  color="inherit"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {`🇺🇸 ${strings.usdPriceOptionTitle()}`}
                </Button>

                <Divider orientation="vertical" sx={{ height: 22 }} />

                <Button
                  size="small"
                  onClick={() => (viewModel.currency = 'cad')}
                  color="inherit"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {`🇨🇦 ${strings.cadPriceOptionTitle()}`}
                </Button>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      )}
    />
  );
});
