import { Currency, plannerSchools } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService, RouteService } from '@/services';
import { SubscriptionLoadable, SubscriptionProductsLoadable, UserDataStore, mergeLoadableStates } from '@/stores';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { computed, makeObservable, observable, override } from 'mobx';
import { UpdatableViewModelState } from '../shared';
import { AppBaseUpdatableDialogViewModel, UpdatableDialogViewModel } from '../utils';
import { AppProductViewModel, ProductViewModel } from './ProductViewModel';

export interface SubscriptionsViewModel extends UpdatableDialogViewModel {
  readonly products: ProductViewModel[];
  readonly isApplying: boolean;
  readonly error: string | undefined;
  currency: Currency;

  confirm(): Promise<void>;
  close(): Promise<void>;
}

export class AppSubscriptionsViewModel extends AppBaseUpdatableDialogViewModel implements SubscriptionsViewModel {
  @observable private _currency: Currency = 'usd';

  constructor(
    private readonly _plannerId: string,
    private readonly _schoolId: string | undefined,
    private readonly _close: () => Promise<void>,
    private readonly _onSubscribe: (sharedSchoolIds: string[]) => Promise<void>,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _route: RouteService = ServiceContainer.services.route,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(localization, _close);
    makeObservable(this);
  }

  @computed
  private get subscriptionLoadable(): SubscriptionLoadable {
    return this._userStore.subscription;
  }

  @computed
  private get availableProductsLoadable(): SubscriptionProductsLoadable {
    return this._userStore.availableSubscriptionProducts;
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  private get ownedPersonalSchoolsInPlanner(): SchoolInformation[] {
    const { userId } = this._userStore.user;
    return plannerSchools(this.planner, this._userStore.schools).filter((s) =>
      s.owners.some(
        (o) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          o.userId === userId && s.school?.isArchived !== true && s.school?.sharingMode !== SchoolSharingMode.SHARED
      )
    );
  }

  readonly hasChanges = false;

  @computed
  get hasData(): boolean {
    return this.subscriptionLoadable.hasData && this.availableProductsLoadable.hasData;
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([this.subscriptionLoadable.state, this.availableProductsLoadable.state]);
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get products(): ProductViewModel[] {
    const dashboardLocation = this._route.resolvePlannerLocation(this._plannerId);
    const redirectUrl = `${window.location.origin}${dashboardLocation}`;
    const schoolsIds = this.ownedPersonalSchoolsInPlanner.map((s) => s.school!.id);

    return this.availableProductsLoadable.data.map(
      (product) =>
        new AppProductViewModel(
          product,
          schoolsIds,
          redirectUrl,
          () => this.currency,
          (sharedSchoolIds) => void this._onSubscribe(sharedSchoolIds),
          this._localization,
          this._userStore
        )
    );
  }

  @computed
  get isApplying(): boolean {
    return this.products.some((product) => product.isApplying);
  }

  @override
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get currency(): Currency {
    return this._currency;
  }

  set currency(value: Currency) {
    this._currency = value;
  }

  confirm() {
    return this._close();
  }

  close() {
    return this._close();
  }

  async reloadData(): Promise<void> {
    await Promise.all([this.subscriptionLoadable.fetch(false), this.availableProductsLoadable.fetch(true)]);
  }
}
