import { useActivePlannerId, usePrevious, useRouteMatch, useServices, useViewModel } from '@/hooks';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LightMuiThemeAppBarColor } from '../../resources/themes';
import { isError } from '../../utils';
import { UserDashboardLayout } from '../layout';
import { InitializingScreen, ServerErrorScreen } from '../shared';
import { PlannerParticipationRequestsDialog } from './management';

interface LocationState {
  mainScreenTeacherShowParticipationRequests?: boolean;
  userDashboardPlannerOptionsShowParticipationRequests?: boolean;
  mainAppBarShowParticipationRequests?: boolean;
}

export interface MainScreenPlannerProps {
  className?: string;
}

export const MainScreenPlanner = observer(({ className }: MainScreenPlannerProps) => {
  const { settingsStorage } = useServices();
  const theme = useTheme();
  const location = useLocation();
  const state: LocationState = (location.state as LocationState) || {};
  const navigate = useNavigate();

  const plannerId = useActivePlannerId();
  const previousPlannerId = usePrevious(plannerId);
  const viewModel = useViewModel((viewModels) => viewModels.createMainScreenPlannerViewModel(plannerId), [plannerId]);

  const canShowCreateScheduleBannerRouteMatch = useRouteMatch([
    'p/:plannerId/home',
    'p/:plannerId/week',
    'p/:plannerId/month'
  ]);

  useEffect(() => {
    if (previousPlannerId !== plannerId) {
      if (
        viewModel.shouldShowPendingParticipationRequests &&
        state.mainScreenTeacherShowParticipationRequests !== true &&
        state.userDashboardPlannerOptionsShowParticipationRequests !== true &&
        state.mainAppBarShowParticipationRequests !== true
      ) {
        viewModel.setHasShownPendingParticipationRequests();
        const newState: LocationState = { ...state, mainScreenTeacherShowParticipationRequests: true };
        navigate(location, { state: newState });
      }
    }
  }, [plannerId]);

  if (viewModel.state == 'pending') {
    return <InitializingScreen />;
  }

  if (isError(viewModel.state)) {
    return <ServerErrorScreen error={viewModel.state} />;
  }

  return (
    <>
      <Helmet>
        <meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content={settingsStorage.backgroundImage?.color ?? theme.palette.background.default}
          data-rh="true"
        />
        <meta
          name="theme-color"
          content={settingsStorage.backgroundImage?.color ?? LightMuiThemeAppBarColor}
          data-rh="true"
        />
      </Helmet>

      <UserDashboardLayout
        className={className}
        canShowCreateScheduleBanner={canShowCreateScheduleBannerRouteMatch != null}
      >
        <Outlet />
      </UserDashboardLayout>

      {state.mainScreenTeacherShowParticipationRequests === true && (
        <PlannerParticipationRequestsDialog isOpen={true} plannerId={plannerId} />
      )}
    </>
  );
});
