import { useActivePlannerId, useViewModel } from '@/hooks';
import { Checkbox, List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Dialog } from '../utils';

export interface PlannerNewCurriculumCoursesDialogProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerNewCurriculumCoursesDialog = observer(
  ({ sx = [], className }: PlannerNewCurriculumCoursesDialogProps) => {
    const plannerId = useActivePlannerId();
    const strings = LocalizedStrings.planner.newCurriculumCourses;
    const viewModel = useViewModel(
      (viewModels) => viewModels.createPlannerNewCurriculumCoursesDialogViewModel(plannerId),
      [plannerId]
    );

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen={viewModel.isOpen}
        title={strings.title()}
        width="sm"
        renderData={() => (
          <Stack spacing={2}>
            <Typography>{viewModel.description}</Typography>

            <List>
              <Stack spacing={1}>
                {viewModel.newCourses.map((cs) => (
                  <Stack
                    key={cs.id}
                    spacing={1}
                    direction="row"
                    sx={{
                      alignItems: 'flex-start'
                    }}
                  >
                    <Checkbox
                      checked={viewModel.selectedCourseIds.includes(cs.id)}
                      onChange={() => viewModel.toggleCourseSelection(cs.id)}
                    />
                    <Stack spacing={0.5}>
                      <Typography
                        sx={{
                          fontWeight: '500'
                        }}
                      >
                        {cs.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.text.secondary
                        }}
                      >
                        {cs.description}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </List>
          </Stack>
        )}
      />
    );
  }
);
