import {
  ConnectedAppKind,
  Picture,
  PublishedWorkEditInfo,
  TimeOfDay,
  UserDashboardInfo,
  UserDashboardKind
} from '@/models';
import { PasteboardPasteContext, SignInActionKind } from '@/services';
import {
  AccountManagementViewModel,
  AdminAccountClassesListKind,
  AdminAccountClassesListViewModel,
  AdminClassDetailsViewModel,
  AdminClassSchedulesFilterOption,
  AdminClassSchedulesViewModel,
  AdminClassesListViewModel,
  AdminDemoSchoolSnapshotsViewModel,
  AdminProfileMenuViewModel,
  AdminSchoolAddAdminDialogViewModel,
  AdminSchoolAdminUsersListViewModel,
  AdminSchoolInviteTeachersDialogViewModel,
  AdminSchoolParticipationCodesViewModel,
  AdminSchoolsListViewModel,
  AdminStudentDetailsViewModel,
  AdminStudentsListViewModel,
  AdminTeachersListViewModel,
  AppAccountManagementViewModel,
  AppAdminAccountClassesListViewModel,
  AppAdminClassDetailsViewModel,
  AppAdminClassSchedulesListViewModel,
  AppAdminClassSchedulesViewModel,
  AppAdminClassesListViewModel,
  AppAdminDemoSchoolSnapshotsViewModel,
  AppAdminProfileMenuViewModel,
  AppAdminSchoolAddAdminDialogViewModel,
  AppAdminSchoolAdminUsersListViewModel,
  AppAdminSchoolInviteTeachersDialogViewModel,
  AppAdminSchoolParticipationCodesViewModel,
  AppAdminSchoolsListViewModel,
  AppAdminStudentDetailsViewModel,
  AppAdminStudentsListViewModel,
  AppAdminTeachersListViewModel,
  AppAsyncConfirmationDialogViewModel,
  AppBackgroundImageViewModel,
  AppConfirmationDialogViewModel,
  AppConfirmationDialogWithChoicesViewModel,
  AppConfirmationDialogWithSingleChoiceViewModel,
  AppConnectedAppEditDialogViewModel,
  AppConnectedAppsViewModel,
  AppCreatePlannerDialogViewModel,
  AppCreateSchoolDialogViewModel,
  AppLoginDialogViewModel,
  AppLoginViewModel,
  AppMainScreenPlannerViewModel,
  AppNoteEditViewModel,
  AppOnboardingViewModel,
  AppPlannedWorkEditViewModel,
  AppPlannerAttachSchoolPickerDialogViewModel,
  AppPlannerCalendarWeekViewModel,
  AppPlannerCourseSectionEditViewModel,
  AppPlannerCourseSectionListViewModel,
  AppPlannerCurriculumCoursesViewModel,
  AppPlannerDistributeItemViewModel,
  AppPlannerItemsViewModel,
  AppPlannerNewCurriculumCoursesDialogViewModel,
  AppPlannerNewSchoolsDialogViewModel,
  AppPlannerParticipationRequestsDialogViewModel,
  AppPlannerPlannerPasteItemViewModel,
  AppPlannerPlanningViewModel,
  AppPlannerRepeatItemViewModel,
  AppPlannerSchoolsListViewModel,
  AppPlannerSideBarViewModel,
  AppPlannerUserDashboardCalendarDatePickerViewModel,
  AppPlannerUserDashboardCalendarDayViewModel,
  AppPlannerUserDashboardCalendarMonthViewModel,
  AppPlannerUserDashboardPeriodInfoPopoverViewModel,
  AppPreferencesViewModel,
  AppProfileMenuViewModel,
  AppPublishedWorkDetailsViewModel,
  AppPublishedWorkEditViewModel,
  AppRenamePlannerDialogViewModel,
  AppScheduleCycleActivityScheduleEditViewModel,
  AppScheduleCycleActivitySchedulesViewModel,
  AppScheduleCycleBellTimesViewModel,
  AppScheduleCycleCreateCopyDialogViewModel,
  AppScheduleCycleCreateDialogViewModel,
  AppScheduleCycleCreationUserDashboardPickerViewModel,
  AppScheduleCycleDetailsEditPageViewModel,
  AppScheduleCycleDetailsViewModel,
  AppScheduleCycleListViewModel,
  AppScheduleCycleMasterScheduleViewModel,
  AppScheduleCyclePeriodScheduleDetailsEditViewModel,
  AppScheduleCycleSpecialDaysPageViewModel,
  AppSchoolCourseSectionDetailsViewModel,
  AppSchoolCourseSectionEditViewModel,
  AppSchoolCourseSectionListViewModel,
  AppSchoolCourseSectionStudentPickerDialogViewModel,
  AppSchoolEditViewModel,
  AppSchoolKindsExplanationDialogViewModel,
  AppServerErrorScreenViewModel,
  AppSharePlannerDialogViewModel,
  AppSharePlannerStatusViewModel,
  AppSpecialDayEditViewModel,
  AppStudentInsightsViewModel,
  AppStudentPlannerPickerViewModel,
  AppStudentPlannerViewModel,
  AppSubscriptionsViewModel,
  AppTeacherInviteStudentsViewModel,
  AppUnsplashViewModel,
  AppUseSharingInvitationCodeDialogViewModel,
  AppUserDashboardPickerViewModel,
  AppUserPropertiesDialogViewModel,
  AppUserPropertiesViewModel,
  AppWorkDetailsViewModel,
  AppWorkEditViewModel,
  AsyncConfirmationDialogViewModel,
  BackgroundImageViewModel,
  ConfirmationDialogAction,
  ConfirmationDialogChoice,
  ConfirmationDialogViewModel,
  ConfirmationDialogWithChoicesViewModel,
  ConfirmationDialogWithSingleChoiceViewModel,
  ConnectedAppEditDialogViewModel,
  ConnectedAppsViewModel,
  CourseSectionEditViewModel,
  CourseSectionListViewModel,
  CreatePlannerDialogViewModel,
  CreateSchoolDialogViewModel,
  LoginDialogViewModel,
  LoginViewModel,
  MainAppBarViewModel,
  MainScreenPlannerViewModel,
  NoteEditViewModel,
  OnboardingViewModel,
  PlannedWorkEditViewModel,
  PlannerAttachSchoolPickerDialogViewModel,
  PlannerCopyItemKind,
  PlannerCurriculumCoursesViewModel,
  PlannerDistributeItemViewModel,
  PlannerItemsViewModel,
  PlannerMainAppBarViewModel,
  PlannerNewCurriculumCoursesDialogViewModel,
  PlannerNewSchoolsDialogViewModel,
  PlannerParticipationRequestsDialogViewModel,
  PlannerPastePlannerItemViewModel,
  PlannerPlanningViewModel,
  PlannerRepeatItemViewModel,
  PlannerSchoolsListViewModel,
  PlannerSideBarViewModel,
  PreferencesViewModel,
  ProfileMenuViewModel,
  PublishedWorkDetailsViewModel,
  PublishedWorkEditViewModel,
  RenamePlannerDialogViewModel,
  ScheduleCycleActivityScheduleEditViewModel,
  ScheduleCycleActivitySchedulesCoursesFilter,
  ScheduleCycleActivitySchedulesViewModel,
  ScheduleCycleCreateCopyDialogViewModel,
  ScheduleCycleCreateDialogViewModel,
  ScheduleCycleCreationUserDashboardPickerViewModel,
  ScheduleCycleDetailsEditPageViewModel,
  ScheduleCycleDetailsViewModel,
  ScheduleCycleListViewModel,
  ScheduleCycleMasterScheduleViewModel,
  ScheduleCyclePeriodScheduleDetailsEditViewModel,
  ScheduleCyclePeriodSchedulesGridViewModel,
  ScheduleCycleSpecialDaysPageViewModel,
  SchoolCourseSectionDetailsViewModel,
  SchoolCourseSectionStudentPickerDialogViewModel,
  SchoolEditViewModel,
  SchoolKindsExplanationDialogViewModel,
  ServerErrorScreenViewModel,
  SharePlannerDialogViewModel,
  SharePlannerStatusViewModel,
  SpecialDayEditViewModel,
  StudentInsightsViewModel,
  StudentPlannerPickerViewModel,
  StudentPlannerViewModel,
  SubscriptionsViewModel,
  TeacherInviteStudentsViewModel,
  UnsplashViewModel,
  UseSharingInvitationCodeDialogViewModel,
  UserDashboardCalendarDatePickerViewModel,
  UserDashboardCalendarDayViewModel,
  UserDashboardCalendarMonthViewModel,
  UserDashboardCalendarWeekViewModel,
  UserDashboardPeriodInfoPopoverViewModel,
  UserDashboardPickerViewModel,
  UserPropertiesDialogViewModel,
  UserPropertiesViewModel,
  UserSharingInvitationCodeResult,
  WorkDetailsViewModel,
  WorkEditViewModel
} from '@/viewmodels';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { reaction } from 'mobx';
import { SpecialDayOccurrenceWhen } from '../stores';
import {
  AdminTeacherDetailsViewModel,
  AppAdminTeacherDetailsViewModel
} from '../viewmodels/admin/AdminTeacherDetailsViewModel';
import { ServiceProvider } from './ServiceProvider';
import { ViewModelProvider } from './ViewModelProvider';

type ViewModelCache<ViewModel> = Map<string, ViewModel>;

export class AppViewModelProvider implements ViewModelProvider {
  // CACHES
  private readonly _cachedMainAppBarViewModels: ViewModelCache<MainAppBarViewModel> = new Map();
  private readonly _cachedProfileMenuViewModels: ViewModelCache<ProfileMenuViewModel> = new Map();
  private readonly _cachedUserDashboardPickerViewModels: ViewModelCache<UserDashboardPickerViewModel> = new Map();
  private readonly _cachedStudentInsightsViewModels: ViewModelCache<StudentInsightsViewModel> = new Map();
  private readonly _cachedStudentPlannerViewModels: ViewModelCache<StudentPlannerViewModel> = new Map();
  private readonly _cachedCourseSectionDetailsViewModels: ViewModelCache<SchoolCourseSectionDetailsViewModel> =
    new Map();

  // CONSTRUCTOR

  constructor(services: ServiceProvider) {
    reaction(
      () => ({ user: services.user.currentUser, isDemoMode: services.settingsStorage.isDemoMode }),
      () => this.resetCache()
    );
  }

  // ONBOARDING

  createLoginViewModel(actionKind: SignInActionKind): LoginViewModel {
    return new AppLoginViewModel(actionKind);
  }

  createLoginDialogViewModel(onClose: () => void): LoginDialogViewModel {
    return new AppLoginDialogViewModel(onClose);
  }

  createOnboardingViewModel(): OnboardingViewModel {
    return new AppOnboardingViewModel();
  }

  // SHARED

  createServerErrorScreenViewModel(error: Error): ServerErrorScreenViewModel {
    return new AppServerErrorScreenViewModel(error);
  }

  createMainAppBarViewModel(plannerId: string): MainAppBarViewModel {
    return this.getOrCreateViewModel(plannerId, this._cachedMainAppBarViewModels, () => {
      return new PlannerMainAppBarViewModel(plannerId);
    });
  }

  createProfileMenuViewModel(plannerId: string): ProfileMenuViewModel {
    return this.getOrCreateViewModel(plannerId, this._cachedProfileMenuViewModels, () => {
      return new AppProfileMenuViewModel(plannerId);
    });
  }

  createUserDashboardPickerViewModel(plannerId: string): UserDashboardPickerViewModel {
    return this.getOrCreateViewModel(
      plannerId,
      this._cachedUserDashboardPickerViewModels,
      () => new AppUserDashboardPickerViewModel(plannerId)
    );
  }

  createStudentPlannerPickerViewModel(onClose: () => Promise<void>): StudentPlannerPickerViewModel {
    return new AppStudentPlannerPickerViewModel(onClose);
  }

  createUseSharingInvitationCodeViewModel(
    plannerId: string | undefined,
    onSuccess: (result: UserSharingInvitationCodeResult) => Promise<void>,
    onCancel: () => Promise<void>
  ): UseSharingInvitationCodeDialogViewModel {
    return new AppUseSharingInvitationCodeDialogViewModel(plannerId, onSuccess, onCancel);
  }

  createPreferencesViewModel(): PreferencesViewModel {
    return new AppPreferencesViewModel();
  }

  createBackgroundImageViewModel(close: () => Promise<void>): BackgroundImageViewModel {
    return new AppBackgroundImageViewModel(close);
  }

  createUnsplashViewModel(
    onSuccess: (picture: Picture) => Promise<void>,
    onCancel: () => Promise<void>
  ): UnsplashViewModel {
    return new AppUnsplashViewModel(onSuccess, onCancel);
  }

  createSubscriptionsViewModel(
    plannerId: string,
    schoolId: string | undefined,
    close: () => Promise<void>,
    onSubscribe: (sharedSchoolIds: string[]) => Promise<void>
  ): SubscriptionsViewModel {
    return new AppSubscriptionsViewModel(plannerId, schoolId, close, onSubscribe);
  }

  createConnectedAppsViewModel(plannerId: string): ConnectedAppsViewModel {
    return new AppConnectedAppsViewModel(plannerId);
  }

  createConnectedAppEditDialogViewModel(
    connectedAppKind: ConnectedAppKind,
    plannerId: string,
    schoolId: string | undefined,
    startSync: boolean,
    onSuccess: (hasChanges: boolean) => Promise<void>,
    onCancel: () => Promise<void>
  ): ConnectedAppEditDialogViewModel {
    return new AppConnectedAppEditDialogViewModel(
      connectedAppKind,
      plannerId,
      schoolId,
      startSync,
      onSuccess,
      onCancel
    );
  }

  createUserPropertiesDialogViewModel(
    onSuccess: () => Promise<void>,
    onCancel: () => Promise<void>
  ): UserPropertiesDialogViewModel {
    return new AppUserPropertiesDialogViewModel(onSuccess, onCancel);
  }

  createUserPropertiesViewModel(): UserPropertiesViewModel {
    return new AppUserPropertiesViewModel(false);
  }

  createAccountManagementViewModel(): AccountManagementViewModel {
    return new AppAccountManagementViewModel();
  }

  createUserDashboardCalendarDayViewModel(plannerId: string): UserDashboardCalendarDayViewModel {
    return new AppPlannerUserDashboardCalendarDayViewModel(plannerId);
  }

  createUserDashboardCalendarWeekViewModel(plannerId: string): UserDashboardCalendarWeekViewModel {
    return new AppPlannerCalendarWeekViewModel(plannerId);
  }

  createUserDashboardCalendarMonthViewModel(plannerId: string): UserDashboardCalendarMonthViewModel {
    return new AppPlannerUserDashboardCalendarMonthViewModel(plannerId);
  }

  createUserDashboardPeriodInfoPopoverViewModel(
    plannerId: string,
    date: Date,
    startTime: TimeOfDay,
    endTime: TimeOfDay,
    periodLabel: string,
    courseSectionId: string | undefined
  ): UserDashboardPeriodInfoPopoverViewModel {
    return new AppPlannerUserDashboardPeriodInfoPopoverViewModel(
      plannerId,
      date,
      startTime,
      endTime,
      periodLabel,
      courseSectionId
    );
  }

  // PLANNER

  createMainScreenPlannerViewModel(plannerId: string): MainScreenPlannerViewModel {
    return new AppMainScreenPlannerViewModel(plannerId);
  }

  createPlannerSideBarViewModel(plannerId: string): PlannerSideBarViewModel {
    return new AppPlannerSideBarViewModel(plannerId);
  }

  createPlannerPlanningViewModel(plannerId: string): PlannerPlanningViewModel {
    return new AppPlannerPlanningViewModel(plannerId);
  }

  createSharePlannerDialogViewModel(
    targetRelationship: PlannerRelationshipKind,
    plannerId: string,
    onDismiss: () => Promise<void>
  ): SharePlannerDialogViewModel {
    return new AppSharePlannerDialogViewModel(targetRelationship, plannerId, onDismiss);
  }

  createSharePlannerStatusViewModel(plannerId: string): SharePlannerStatusViewModel {
    return new AppSharePlannerStatusViewModel(plannerId);
  }

  createCreatePlannerDialogViewModel(onClose: (plannerId: string | undefined) => void): CreatePlannerDialogViewModel {
    return new AppCreatePlannerDialogViewModel(onClose);
  }

  createRenamePlannerDialogViewModel(
    plannerId: string,
    onClose: (() => void) | undefined,
    onSaveError: ((error: Error) => void) | undefined
  ): RenamePlannerDialogViewModel {
    return new AppRenamePlannerDialogViewModel(plannerId, onClose, onSaveError);
  }

  createPlannerNewSchoolsDialogViewModel(plannerId: string): PlannerNewSchoolsDialogViewModel {
    return new AppPlannerNewSchoolsDialogViewModel(plannerId);
  }

  createPlannerNewCurriculumCoursesDialogViewModel(plannerId: string): PlannerNewCurriculumCoursesDialogViewModel {
    return new AppPlannerNewCurriculumCoursesDialogViewModel(plannerId);
  }

  createWorkDetailsViewModel(workId: string, plannerId: string, close: () => Promise<void>): WorkDetailsViewModel {
    return new AppWorkDetailsViewModel(workId, plannerId, close);
  }

  createWorkEditViewModel(
    workId: string | undefined,
    newWorkDefaultValues: PartialMessage<Work> | undefined,
    plannerId: string,
    onSuccess: (work: Work) => Promise<void>,
    onCancel: () => Promise<void>
  ): WorkEditViewModel {
    return new AppWorkEditViewModel(workId, newWorkDefaultValues, plannerId, onSuccess, onCancel);
  }

  createNoteEditViewModel(
    noteId: string | undefined,
    newNoteDefaultValues: PartialMessage<Note> | undefined,
    plannerId: string,
    onSuccess: (note: Note) => Promise<void>,
    onCancel: () => Promise<void>
  ): NoteEditViewModel {
    return new AppNoteEditViewModel(noteId, newNoteDefaultValues, plannerId, onSuccess, onCancel);
  }

  createPublishedWorkDetailsViewModel(
    id: string,
    schoolId: string,
    plannerId: string,
    onClose: () => Promise<void>
  ): PublishedWorkDetailsViewModel {
    return new AppPublishedWorkDetailsViewModel(id, schoolId, plannerId, onClose);
  }

  createPublishedWorkEditViewModel(
    ids: { publishedWorkId: string; schoolId: string } | undefined,
    plannerId: string,
    newPublishedWorkInfo: Partial<PublishedWorkEditInfo> | undefined,
    onSuccess: (work: PublishedWork) => Promise<void>,
    onCancel: () => Promise<void>
  ): PublishedWorkEditViewModel {
    return new AppPublishedWorkEditViewModel(ids, newPublishedWorkInfo, plannerId, onSuccess, onCancel);
  }

  createPlannedWorkEditViewModel(
    plannerId: string,
    workId: string,
    plannedWorkId: string | undefined,
    onSuccess: (work: Work) => Promise<void>,
    onCancel: () => Promise<void>
  ): PlannedWorkEditViewModel {
    return new AppPlannedWorkEditViewModel(plannerId, workId, plannedWorkId, onCancel, onSuccess);
  }

  createPlannerPersonalCourseSectionListViewModel(plannerId: string): CourseSectionListViewModel {
    return new AppPlannerCourseSectionListViewModel(plannerId, 'personal');
  }

  createPlannerExternalCourseSectionListViewModel(plannerId: string): CourseSectionListViewModel {
    return new AppPlannerCourseSectionListViewModel(plannerId, 'external');
  }

  createPlannerCurriculumCoursesViewModel(plannerId: string): PlannerCurriculumCoursesViewModel {
    return new AppPlannerCurriculumCoursesViewModel(plannerId);
  }

  createCourseSectionEditViewModel(
    dashboardKind: UserDashboardKind,
    dashboardId: string,
    courseSectionId: string | undefined,
    onSuccess: (courseSectionId: string) => Promise<void>,
    onCancel: () => Promise<void>
  ): CourseSectionEditViewModel {
    switch (dashboardKind) {
      case 'planner':
        return new AppPlannerCourseSectionEditViewModel(courseSectionId, dashboardId, onSuccess, onCancel);
      case 'school':
        return new AppSchoolCourseSectionEditViewModel(courseSectionId, dashboardId, onSuccess, onCancel);
    }
  }

  createTeacherInviteStudentsViewModel(
    schoolId: string,
    onDismiss: () => Promise<void>
  ): TeacherInviteStudentsViewModel {
    return new AppTeacherInviteStudentsViewModel(schoolId, onDismiss);
  }

  createPlannerParticipationRequestsViewModel(
    plannerId: string,
    onDismiss: () => Promise<void>
  ): PlannerParticipationRequestsDialogViewModel {
    return new AppPlannerParticipationRequestsDialogViewModel(plannerId, onDismiss);
  }

  createPlannerSchoolsListViewModel(plannerId: string): PlannerSchoolsListViewModel {
    return new AppPlannerSchoolsListViewModel(plannerId);
  }

  createPlannerAttachSchoolPickerDialogViewModel(
    plannerId: string,
    onDismiss: () => Promise<void>
  ): PlannerAttachSchoolPickerDialogViewModel {
    return new AppPlannerAttachSchoolPickerDialogViewModel(plannerId, onDismiss);
  }

  createPlannerItemsViewModel(plannerId: string, courseSectionId: string | undefined): PlannerItemsViewModel {
    return new AppPlannerItemsViewModel(plannerId, courseSectionId);
  }

  // SCHEDULE

  createSchedulesListViewModel(
    dashboardId: string,
    dashboardKind: UserDashboardKind,
    initialShowPastSchedulesValue: boolean
  ): ScheduleCycleListViewModel {
    return new AppScheduleCycleListViewModel(dashboardId, dashboardKind, initialShowPastSchedulesValue);
  }

  createScheduleCreateDialogViewModel(
    dashboardId: string,
    dashboardKind: UserDashboardKind,
    plannerId: string | undefined,
    onDismiss: (scheduleId: string | undefined) => void
  ): ScheduleCycleCreateDialogViewModel {
    return new AppScheduleCycleCreateDialogViewModel(dashboardId, dashboardKind, plannerId, onDismiss);
  }

  createScheduleCreateCopyDialogViewModel(
    scheduleCycle: ScheduleCycle,
    dashboardId: string,
    dashboardKind: UserDashboardKind,
    onDismiss: (scheduleId: string | undefined) => void
  ): ScheduleCycleCreateCopyDialogViewModel {
    return new AppScheduleCycleCreateCopyDialogViewModel(scheduleCycle, dashboardId, dashboardKind, onDismiss);
  }

  createScheduleCycleCreationUserDashboardPickerViewModel(
    plannerId: string,
    onSubmit: (dashboard: UserDashboardInfo) => Promise<void>,
    onCancel: () => Promise<void>
  ): ScheduleCycleCreationUserDashboardPickerViewModel {
    return new AppScheduleCycleCreationUserDashboardPickerViewModel(plannerId, onSubmit, onCancel);
  }

  createScheduleEditDetailsPageViewModel(
    scheduleCycleId: string,
    dashboard: UserDashboardInfo
  ): ScheduleCycleDetailsEditPageViewModel {
    return new AppScheduleCycleDetailsEditPageViewModel(dashboard, scheduleCycleId);
  }

  createScheduleCycleDetailsViewModel(
    scheduleCycleId: string,
    dashboardId: string,
    dashboardKind: UserDashboardKind
  ): ScheduleCycleDetailsViewModel {
    return new AppScheduleCycleDetailsViewModel(scheduleCycleId, dashboardId, dashboardKind);
  }

  createScheduleCycleMasterScheduleViewModel(
    dashboard: UserDashboardInfo,
    scheduleCycleId: string,
    plannerId: string | undefined,
    displayedTermId: string,
    filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined
  ): ScheduleCycleMasterScheduleViewModel {
    return new AppScheduleCycleMasterScheduleViewModel(dashboard, scheduleCycleId, plannerId, displayedTermId, filters);
  }

  createScheduleCycleSpecialDaysPageViewModel(
    scheduleCycleId: string,
    dashboard: UserDashboardInfo
  ): ScheduleCycleSpecialDaysPageViewModel {
    return new AppScheduleCycleSpecialDaysPageViewModel(scheduleCycleId, dashboard);
  }

  createSpecialDayEditDialogViewModel(
    specialDayId: string | undefined,
    scheduleCycleId: string,
    dashboard: UserDashboardInfo,
    initialOccurrence: SpecialDayOccurrenceWhen | undefined,
    onDismiss: () => Promise<void>
  ): SpecialDayEditViewModel {
    return new AppSpecialDayEditViewModel(specialDayId, scheduleCycleId, dashboard, initialOccurrence, onDismiss);
  }

  createScheduleCycleBellTimesPageViewModel(
    dashboard: UserDashboardInfo,
    plannerId: string | undefined,
    scheduleCycleId: string
  ): ScheduleCyclePeriodSchedulesGridViewModel {
    return new AppScheduleCycleBellTimesViewModel(dashboard, plannerId, scheduleCycleId);
  }

  createScheduleCyclePeriodScheduleDetailsEditViewModel(
    periodScheduleId: string | undefined,
    scheduleCycleId: string,
    dashboard: UserDashboardInfo
  ): ScheduleCyclePeriodScheduleDetailsEditViewModel {
    return new AppScheduleCyclePeriodScheduleDetailsEditViewModel(dashboard, periodScheduleId, scheduleCycleId);
  }

  createScheduleCycleActivitySchedulesViewModel(
    scheduleCycleId: string,
    dashboard: UserDashboardInfo,
    plannerId: string
  ): ScheduleCycleActivitySchedulesViewModel {
    return new AppScheduleCycleActivitySchedulesViewModel(dashboard, plannerId, scheduleCycleId);
  }

  createScheduleCycleActivityScheduleEditViewModel(
    activityScheduleId: string | undefined,
    activity: Activity,
    scheduleCycleId: string,
    dashboard: UserDashboardInfo,
    plannerId: string | undefined,
    filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined
  ): ScheduleCycleActivityScheduleEditViewModel {
    return new AppScheduleCycleActivityScheduleEditViewModel(
      activityScheduleId,
      activity,
      scheduleCycleId,
      filters,
      dashboard,
      plannerId
    );
  }

  createPlannerPastePlannerItemViewModel(
    plannerId: string,
    context: PasteboardPasteContext,
    onDismiss: () => Promise<void>
  ): PlannerPastePlannerItemViewModel {
    return new AppPlannerPlannerPasteItemViewModel(plannerId, context, onDismiss);
  }

  createPlannerDistributeItemViewModel(
    plannerId: string,
    itemKind: PlannerCopyItemKind,
    onDismiss: () => Promise<void>
  ): PlannerDistributeItemViewModel {
    return new AppPlannerDistributeItemViewModel(plannerId, itemKind, onDismiss);
  }

  createPlannerRepeatItemViewModel(
    plannerId: string,
    itemKind: PlannerCopyItemKind,
    onDismiss: () => Promise<void>
  ): PlannerRepeatItemViewModel {
    return new AppPlannerRepeatItemViewModel(plannerId, itemKind, onDismiss);
  }

  // SCHOOL

  createSchoolEditViewModel(schoolId: string): SchoolEditViewModel {
    return new AppSchoolEditViewModel(schoolId);
  }

  createCreateSchoolDialogViewModel(
    plannerId: string | undefined,
    close: ((schoolId: string | undefined, attachError: Error | undefined) => Promise<void>) | undefined
  ): CreateSchoolDialogViewModel {
    return new AppCreateSchoolDialogViewModel(plannerId, close);
  }

  createSchoolKindsExplanationDialogViewModel(onDismiss: () => Promise<void>): SchoolKindsExplanationDialogViewModel {
    return new AppSchoolKindsExplanationDialogViewModel(onDismiss);
  }

  createStudentInsightsViewModel(
    schoolId: string,
    studentId: string,
    courseSectionId: string,
    onCancel: () => Promise<void>
  ): StudentInsightsViewModel {
    const key = `${courseSectionId}/${studentId}`;
    return this.getOrCreateViewModel(
      key,
      this._cachedStudentInsightsViewModels,
      () => new AppStudentInsightsViewModel(schoolId, studentId, courseSectionId, onCancel)
    );
  }

  createStudentPlannerViewModel(plannerId: string): StudentPlannerViewModel {
    return this.getOrCreateViewModel(
      plannerId,
      this._cachedStudentPlannerViewModels,
      () => new AppStudentPlannerViewModel(plannerId)
    );
  }

  createCourseSectionDetailsViewModel(
    courseSectionId: string,
    plannerId: string,
    schoolId: string
  ): SchoolCourseSectionDetailsViewModel {
    const key = `${schoolId}/${courseSectionId}`;
    return this.getOrCreateViewModel(
      key,
      this._cachedCourseSectionDetailsViewModels,
      () => new AppSchoolCourseSectionDetailsViewModel(plannerId, schoolId, courseSectionId)
    );
  }

  createSchoolCourseSectionStudentPickerDialogViewModel(
    onDismiss: () => void
  ): SchoolCourseSectionStudentPickerDialogViewModel {
    return new AppSchoolCourseSectionStudentPickerDialogViewModel(onDismiss);
  }

  createSchoolCourseSectionListViewModel(schoolId: string, plannerId: string): CourseSectionListViewModel {
    return new AppSchoolCourseSectionListViewModel(schoolId, plannerId);
  }

  // DIALOGS

  createConfirmationDialogViewModel(
    confirm: (() => void) | undefined,
    cancel: (() => void) | undefined,
    showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    isDestructive: boolean | undefined
  ): ConfirmationDialogViewModel {
    return new AppConfirmationDialogViewModel(
      confirm,
      cancel,
      showCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      isDestructive
    );
  }

  createConfirmationDialogWithSingleChoiceViewModel(
    confirm: ((selectedChoiceKey: string) => Promise<void>) | undefined,
    cancel: (() => Promise<void>) | undefined,
    showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    additionalActions: ConfirmationDialogAction[] | undefined,
    choices: ConfirmationDialogChoice[],
    initialSelectedKey: string | undefined,
    isDestructive: boolean | undefined
  ): ConfirmationDialogWithSingleChoiceViewModel {
    return new AppConfirmationDialogWithSingleChoiceViewModel(
      confirm,
      cancel,
      showCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      additionalActions,
      choices,
      initialSelectedKey,
      isDestructive
    );
  }

  createConfirmationDialogWithChoicesViewModel(
    confirm: ((selectedChoicesKeys: string[]) => Promise<void>) | undefined,
    cancel: (() => Promise<void>) | undefined,
    showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    additionalActions: ConfirmationDialogAction[] | undefined,
    choices: ConfirmationDialogChoice[],
    requiresAtLeastOneSelection: boolean | undefined,
    initialSelectedChoicesKeys: string[] | undefined,
    isDestructive: boolean | undefined
  ): ConfirmationDialogWithChoicesViewModel {
    return new AppConfirmationDialogWithChoicesViewModel(
      confirm,
      cancel,
      showCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      additionalActions,
      choices,
      requiresAtLeastOneSelection,
      initialSelectedChoicesKeys,
      isDestructive
    );
  }

  createAsyncConfirmationDialogViewModel(
    confirm: (() => Promise<void>) | undefined,
    cancel: (() => Promise<void>) | undefined,
    showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    isDestructive: boolean | undefined
  ): AsyncConfirmationDialogViewModel {
    return new AppAsyncConfirmationDialogViewModel(
      confirm,
      cancel,
      showCancel,
      confirmButtonLabel,
      cancelButtonLabel,
      isDestructive
    );
  }

  // UTILS

  createUserDashboardCalendarDatePickerViewModel(
    plannerId: string,
    initialDate: Date | undefined,
    selectedDates: () => Date[],
    highlightedDates: (startDate: Date, endDate: Date) => Date[],
    minDate: Date | undefined,
    maxDate: Date | undefined
  ): UserDashboardCalendarDatePickerViewModel {
    return new AppPlannerUserDashboardCalendarDatePickerViewModel(
      plannerId,
      initialDate,
      selectedDates,
      highlightedDates,
      minDate,
      maxDate
    );
  }

  // ADMIN

  createAdminSchoolsListViewModel(): AdminSchoolsListViewModel {
    return new AppAdminSchoolsListViewModel();
  }

  createAdminProfileMenuViewModel(): AdminProfileMenuViewModel {
    return new AppAdminProfileMenuViewModel();
  }

  createAdminClassesListViewModel(schoolId: string): AdminClassesListViewModel {
    return new AppAdminClassesListViewModel(schoolId);
  }

  createAdminClassDetailsViewModel(courseSectionId: string, schoolId: string): AdminClassDetailsViewModel {
    return new AppAdminClassDetailsViewModel(courseSectionId, schoolId);
  }

  createAdminTeachersListViewModel(schoolId: string): AdminTeachersListViewModel {
    return new AppAdminTeachersListViewModel(schoolId);
  }

  createAdminSchoolInviteTeachersDialogViewModel(
    schoolId: string,
    onDismiss: () => Promise<void>
  ): AdminSchoolInviteTeachersDialogViewModel {
    return new AppAdminSchoolInviteTeachersDialogViewModel(schoolId, onDismiss);
  }

  createAdminTeacherDetailsViewModel(teacherId: string, schoolId: string): AdminTeacherDetailsViewModel {
    return new AppAdminTeacherDetailsViewModel(teacherId, schoolId);
  }

  createAdminStudentsListViewModel(schoolId: string): AdminStudentsListViewModel {
    return new AppAdminStudentsListViewModel(schoolId);
  }

  createAdminStudentDetailsViewModel(studentId: string, schoolId: string): AdminStudentDetailsViewModel {
    return new AppAdminStudentDetailsViewModel(studentId, schoolId);
  }

  createAdminClassSchedulesViewModel(schoolId: string): AdminClassSchedulesViewModel {
    return new AppAdminClassSchedulesViewModel(schoolId);
  }

  createAdminClassSchedulesListViewModel(
    schoolId: string,
    scheduleCycleId: string,
    filter: AdminClassSchedulesFilterOption | undefined
  ): ScheduleCycleActivitySchedulesViewModel {
    return new AppAdminClassSchedulesListViewModel(schoolId, scheduleCycleId, filter);
  }

  createAdminAccountClassesListViewModel(
    accountId: string,
    schoolId: string,
    kind: AdminAccountClassesListKind
  ): AdminAccountClassesListViewModel {
    return new AppAdminAccountClassesListViewModel(accountId, schoolId, kind);
  }

  createAdminSchoolParticipationCodesViewModel(schoolId: string): AdminSchoolParticipationCodesViewModel {
    return new AppAdminSchoolParticipationCodesViewModel(schoolId);
  }

  createAdminDemoSchoolSnapshotsViewModel(schoolId: string): AdminDemoSchoolSnapshotsViewModel {
    return new AppAdminDemoSchoolSnapshotsViewModel(schoolId);
  }

  createAdminSchoolAdminUsersListViewModel(schoolId: string): AdminSchoolAdminUsersListViewModel {
    return new AppAdminSchoolAdminUsersListViewModel(schoolId);
  }

  createAdminSchoolAddAdminViewModel(
    schoolId: string,
    onDismiss: () => Promise<void>
  ): AdminSchoolAddAdminDialogViewModel {
    return new AppAdminSchoolAddAdminDialogViewModel(schoolId, onDismiss);
  }

  // PRIVATE FUNCTIONS

  private getOrCreateViewModel<ViewModel>(
    key: string,
    cache: ViewModelCache<ViewModel>,
    builder: () => ViewModel
  ): ViewModel {
    const cachedViewModel = cache.get(key);

    if (cachedViewModel != null) {
      return cachedViewModel;
    }

    const viewModel = builder();
    cache.set(key, viewModel);
    return viewModel;
  }

  private resetCache() {
    this._cachedMainAppBarViewModels.clear();
    this._cachedProfileMenuViewModels.clear();
    this._cachedUserDashboardPickerViewModels.clear();
    this._cachedStudentInsightsViewModels.clear();
    this._cachedStudentPlannerViewModels.clear();
    this._cachedCourseSectionDetailsViewModels.clear();
  }
}
